angular
    .module('annexaApp')
    .component('annexaBoxNotificationsPaginated',{
        templateUrl: './components/reg/annexa-box-notifications-paginated/annexa-box-notifications-paginated.html',
        controller:['AnnexaFormlyFactory', 'NotificationFactory', '$scope', 'GlobalDataFactory', '$rootScope', 'CommonService', 'Language', '$q', 'TramNewFactory', '$filter', 'RestService', function(AnnexaFormlyFactory, NotificationFactory, $scope, GlobalDataFactory, $rootScope, CommonService, Language, $q, TramNewFactory, $filter, RestService) {
            var vm = this;

            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASNotifications') {
                    /*_.forEach(args.selected, function (item) {
                        vm.box.search.searchedValue = { id: item.id, object: item };
                        vm.box.search.searchAdd();
                    });*/
                    
                    if(args.selected && args.selected.length > 0){
                    	vm.box.search.searchedValue = { object: args.selected };
                    	vm.box.search.saveSearch(args.selected);
                    }

                    args.modal.close();
                }
            });
            
            $scope.$on('created_multiple_notifications', function (event, args) {
            	var isThisBox = true;
            	if(args.parentIsModal){
            		if(args.parentIsModal == "isModal" && vm.isModal){
            			isThisBox = true;
            		}else if(args.parentIsModal == "isNotModal" && !vm.isModal){
            			isThisBox = true;
            		}
            	}
            	if(isThisBox){
	                if(vm.box && vm.box.search && vm.box.search.saveSearch) {
	                    vm.box.search.saveSearch(args.notifications, args.modal);
	                }
            	}
            });
            
            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
	                vm.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
	                vm.notificationModule = true;
	                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality){
	                    vm.notificationModule = $rootScope.app.configuration.show_notification_functionality.value;
	                }
	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.literals.notifications';
	                }
	
	                if(vm.box.search) {
	                    vm.box.search.origin = 'reg';
	                    vm.box.search.type = 'NotificationThird';

	                    vm.box.search.searchFunc = function(val) {
	                        var added = $linq(vm.box.content).select(vm.box.search.addedSelect).toArray();
	                        
	                        var filter = vm.searchByEntryType ?  { entryType: vm.entryType } : {};

	                        var additionalFilter = vm.box.search.additionalFilter ? vm.box.search.additionalFilter : {};
	                        additionalFilter.loggedUser = $rootScope.LoggedUser.id;
	                        additionalFilter.added = added;
	                        additionalFilter.langColumn = Language.getActiveColumn();
	                        additionalFilter.searchBox = val.val;
	                        additionalFilter.isAdvanced = true;
	                        if(vm.box.origin == 'annexa-box-object-remittance') {
	                        	additionalFilter.paginated = true;
	                        	additionalFilter.paginatedObject = vm.box.tableDefinition.objectType;
	                        	additionalFilter.paginatedObjectId = vm.box.tableDefinition.objectIdToAdded;
	                        }

	                        return RestService.filterData(vm.box.search.origin, vm.box.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
	                            .then(function (data) {
	                                var response = [];

	                                _.forEach(data.data.content, function(value, key) {
	                                    response.push({
	                                        id: value.id,
	                                        object: value.notification,
	                                        value: vm.box.search.processSearch(value)
	                                    });
	                                });

	                                response = new SortedArray(response, 'value').sort();

	                                return response;
	                            });

	                    }


	                    vm.box.search.processSearch = function(object) {
	                        return object.notification.registerEntry.entryNumber + (object.notification.extract ? ' ' + object.notification.extract : '');
	                    }

	                    if(!vm.box.search.completeAdd) {
	                        vm.box.search.completeAdd = function() {
	                        	var newContent = [];
	                        	if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                        		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                        			newContent.push(obj);
	                        		});
	                        	}else{
	                        		newContent.push(vm.box.search.searchedValue.object);
	                        	}

	                    		/*_.forEach(newContent, function(obj){
	                    			vm.box.content.push(obj);
	                            });*/
	                    		  
	                    		vm.box.search.searchedValue = { id: -1, value: '' };
	                            if(vm.box.search.saveSearch ) {
	                                vm.box.search.saveSearch(newContent);
	                            }
	                        }
	                    }

	                    vm.box.search.advancedSearch = function() {
	                    	
	                    	$scope.notificationStates = angular.copy(NotificationFactory.notificationGlobalStatus);
	                        $scope.notificationStates.unshift({
	                            id: '',
	                            icon: 'minus',
	                            style: 'text-black',
	                            name: $filter('translate')('global.literals.all'),
	                            orderStatus: 0
	                        });
	                        
	                        $scope.headerIconNotificationGlobalStatus = function(icon) {
	                            if($scope.notificationStates && $scope.notificationStates.length > 0 && $scope.filterData.state) {
	                                var status = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
	                                if(status) {
	                                    if(icon) {
	                                        return status.icon;
	                                    } else {
	                                        return status.style;
	                                    }
	                                }
	                            }
	                            if(icon) {
	                                return 'minus';
	                            } else {
	                                return 'false';
	                            }
	                        }
	                        
	                        $scope.setNotificationGlobalTypeDT = function(state){
	                            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
	                            if($scope.tableDefinition){ 
	                            	$scope.changeStatusFromHeader = true;
	                            	$scope.tableDefinition.reloadInternalData(true, true);
	                            }else{
	                                var scopeAux = angular.element('#tableAdvancedSearchNotifications').scope();
	                                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
	                                    scopeAux.tableDefinition.reloadInternalData(true, true);
	                                }
	                            }
	                        }
	                        
	                    	var notificationGlobalStatusTitle = function() {
	                            var content = '';
	                 
	                            content += '<div>';
	                            content += '    <div class="btn-group dropdown">';
	                            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
	                            content += '            <span class="fa-stack">';
	                            content += '                <i class="fa fa-{{headerIconNotificationGlobalStatus(true)}} {{headerIconNotificationGlobalStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
	                            content += '            </span>';
	                            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
	                            content += '        </button>';
	                            content += '        <div class="dropdown-menu">';
	                            content += '            <a class="dropdown-item" ng-repeat="state in notificationStates | orderBy:\'orderStatus\'" ng-click="setNotificationGlobalTypeDT(state);">';
	                            content += '                <span class="fa-stack">';
	                            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
	                            content += '                </span>';
	                            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
	                            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
	                            content += '            </a>';
	                            content += '        </div>';
	                            content += '    </div>';
	                            content += '</div>';

	                            return content;
	                        }
	                        
	                    	var notificationGlobalStatusRender = function(data, type, full, meta) {
	                            var content = '';

	                            if($scope.notificationStates && $scope.notificationStates.length > 0) {
	                                var state = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

	                                if(state) {
	                                    content += '<div class="text-center">';
	                                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
	                                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
	                                    content += '    </span>';
	                                    content += '</div>';
	                                }
	                            }

	                            return content;
	                        }
	                    	
	                    	var renderNotificationLastStatus = function (data, type, full, meta) {
	                            var orderedByDateStatuses = $linq(data).orderBy("x => x.createdDate").toArray();

	                            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
	                                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
	                        };

	                        var getAddedFunction = function() {
	                        	var added = $linq(vm.content).select("x => x.id").toArray();
	                        	
	                        	$linq(vm.content).select(function (x) { 
	                        		if(x.notification) {
	                        			return x.notification.id;
	                        		} else {
	                        			return x.id;
	                        		}
	                        	}).toArray();

	                        	if (vm.entityId) {
	                            	added.push(vm.entityId);
	                            }
	                        	
	                        	return added;
	                        }
	                        
	                        var advancedSearchModal = {
	                            title: 'global.literals.advanced_search_notifications',
	                            size: 'modal-lg',
	                            filter: [
	                                { id: 'extract', type: 'text', order: 1, label: 'global.literals.extract', callAux: true, userChoosen: false},
	                                { id: 'notificationType', type: 'select', order: 2, label: 'global.literals.notificationTypes', dataType: 'LOCAL', data: GlobalDataFactory.notificationTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: false},
	                                { id: 'globalStatus', type: 'select', order: 3, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: NotificationFactory.notificationGlobalStatus, nameProperty: 'name', addAll: true, callAux: true, userChoosen: false},
	                                { id: 'status', type: 'select', order: 4, label: 'global.literals.particularStatus', dataType: 'LOCAL', data: GlobalDataFactory.notificationStatuses, nameProperty: Language.getActiveColumn(), addAll: true, callAux: true, userChoosen: true},
	                                { id: 'entryNumber', type: 'text', order: 5, label: 'global.reg.datatable.numberOutput', callAux: true, userChoosen: false},
	                                { id: 'dossierNumber', type: 'text', order: 6, label: 'global.literals.numExp', callAux: true, userChoosen: true},
	                                { id: 'thirdName', type: 'text', order: 7, label: 'global.literals.receivers', callAux: true, userChoosen: false},
	                                { id: 'userCreator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true, userChoosen: true},
	                                { id: 'createdDate', type: 'dateRange', order: 9, label: 'global.literals.creation_date', callAux:true, userChoosen: true},
	                                { id: 'originEntryNumber', type: 'text', order: 10, label: 'global.literals.originEntryInputNumber', callAux:true, userChoosen: true},
	                                { id: 'originRegisterOrgan', type: 'text', order: 11, label: 'global.literals.originEntryInputOrgan', callAux:true, userChoosen: true}
	                            ],
	                            tableDefinition: {
	                                id: 'tableAdvancedSearchNotifications',
	                                origin: 'reg',
	                                objectType: 'NotificationThird',
	                                sortName: 'createdDate',
	                                sort: [[3, 'asc']],
	                                columns: [
	                                    { id: 'notification.globalStatus', width: '20px',  title: notificationGlobalStatusTitle(), render: notificationGlobalStatusRender, sortable: false },
	                                    { id: 'notification.notificationType.'+ Language.getActiveColumn(), width: '150px', column: new DatabaseTranslatedColumn($filter, 'global.literals.notificationTypes', Language.getActiveColumn()) },
	                                    { id: 'createdDate', width: '120px', column: new DateTimeColumn($filter, 'global.literals.creation_date')},
	                                    { id: 'statuses', title: $filter('translate')('global.literals.state'), render: renderNotificationLastStatus, sortable: false},
	                                    { id: 'notification.extract', width: '120px', title: $filter('translate')('global.literals.extract'), className: 'pre-line'},
	                                    { id: 'notification.registerEntry.registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') },
	                                    { id: 'notification.registerEntry.id', width: '120px', column: new UIRouterStateColumn($filter, 'global.reg.datatable.numberOutput','annexa.reg.output.entries.edit({output:\'[data]\'})', undefined, 'var number = full.notification.registerEntry.entryNumber; number;')},
	                                    { id: 'third', column: new ThirdColumn($filter, 'global.literals.receivers')}
	                                ],
	                                noLoading: true,
	                                skipFirstSearch: true,
	                                callOrigin: 'box',
	                                callAuxToAdd: { paginated:true, paginatedObject:vm.box.tableDefinition.objectIdToSelectType, paginatedObjectId:vm.box.tableDefinition.objectIdToAdded, isAdvanced:true}
	                            },
	                            multiple: true
	                        }
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASNotifications', advancedSearchModal);
	                    }
	                }
	                
	                if(!vm.box.hiddeNew) {
	                    if(!vm.box.new){
	                    	vm.box.new = {};
	                    }
	                    vm.box.new.newFunc = function() {
							if(vm.box.config && vm.box.config.dossier){
								var promises = [];
                                if(vm.box.config.dossier.showThirdInfo && vm.box.config.dossier.showThirdInfo != 'ALL') {
                                    promises.push(TramNewFactory.getDossierThirdsAnon(vm.box.config.dossier.id));
                                } else {
    								promises.push(TramNewFactory.getDossierThirds(vm.box.config.dossier.id));
                                }
			                	promises.push(TramNewFactory.getDossierTransactionDocuments(vm.box.config.dossier.id));
				                $q.all(promises).then(function(data) {
				                    var archiveClassifications = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
				                    var newModal =  NotificationFactory.notificationNewModal(
				                        undefined,
				                        undefined,
				                        vm.box.config.dossier ? angular.copy(vm.box.config.dossier) : undefined,
				                        undefined,
				                        undefined, undefined, undefined, archiveClassifications, ((data && data[0])?JSOG.decode(data[0]):undefined), ((data && data[1])?JSOG.decode(data[1]):undefined)
			                    	);

			                    	if(vm.box.config.dossierTransactionId) {
			                    		newModal.dossierTransactionId = vm.box.config.dossierTransactionId;
			                    	}
			                    	var submitNewNotification = function () {
			                        	NotificationFactory.getNewNotifications(this, newModal, vm.isModal);
			                    	}
			                    	newModal.submitModal = submitNewNotification;
			                    	AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', newModal);
								}).catch(function() {
									vm.box.alerts.length = 0;
		       						vm.box.alerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
			                    })	
							}
		                };
	                }
				}
            };
        }],
        bindings: {
			box: '='
        }
    })