angular
    .module('annexaApp')
    .component('annexaBoxNotifications',{
        templateUrl: './components/reg/annexa-box-notifications/annexa-box-notifications.html',
        controller:['AnnexaFormlyFactory', 'NotificationFactory', '$scope', 'GlobalDataFactory', '$rootScope', 'CommonService', 'Language', 'RestService', '$filter', function(AnnexaFormlyFactory, NotificationFactory, $scope, GlobalDataFactory, $rootScope, CommonService, Language, RestService, $filter) {
            var vm = this;

            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASNotifications') {
                    _.forEach(args.selected, function (item) {
                        vm.search.searchedValue = { id: item.id, object: item };
                        vm.search.searchAdd();
                    });

                    args.modal.close();
                }
            });
            
            $scope.$on('created_multiple_notifications', function (event, args) {
            	var isThisBox = false;
            	if(args.parentIsModal){
            		if(args.parentIsModal == "isModal" && vm.isModal){
            			isThisBox = true;
            		}else if(args.parentIsModal == "isNotModal" && !vm.isModal){
            			isThisBox = true;
            		}
            	}
            	if(isThisBox){
	                if(vm.search && vm.search.saveSearch && !vm.isModal) {
	                    vm.search.saveSearch(args.notifications, args.modal);
	                }else{
	    				var modal = args.modal;
	    				
						var closeModal = function () {
		                    _.forEach(args.notifications, function (item) {
		                        vm.content.push(item);
		                    });
		                	if (modal) modal.close();
						}
	    				
	    				if(args && args.notifications) {
	    					var notifications = args.notifications;
	    					if(!Array.isArray(notifications)){
	    						notifications = [notifications];
	    					}
	    	                if(notifications && notifications.length > 0) {
	    	                	var checkNotifiableRecipients = ($rootScope.app.configuration.check_notifiable_recipients && $rootScope.app.configuration.check_notifiable_recipients.active)?$rootScope.app.configuration.check_notifiable_recipients.active:false;
	    	                	if (checkNotifiableRecipients) {
	    	                		var telematicNotifications = $linq(notifications).where("x => x.notificationType && x.notificationType.addressType && (x.notificationType.addressType == 'TELEMATIC_EMAIL' || x.notificationType.addressType == 'OTHER')").toArray();
	    	                		if (!telematicNotifications) telematicNotifications = [];
	    	                		
	    	                		NotificationFactory.checkNotifiableRecipients(telematicNotifications).then(function(data) {
	    	                			if (data) {
	    	                				var keys = Object.keys(data);
	    	                				if (modal) {
	    	                					modal.alerts.length = 0;
	    	                					var thirdsNoti = $linq(notifications).selectMany("x => x.thirds").toArray();
	    	            			    		_.forEach(keys, function(key){
	    	            			    			var thirdNoti = $linq(thirdsNoti).firstOrDefault(undefined,"x => x.third && x.third.id == "+key+"");
	    	            			    			if(data[key] == 'No check notifiable recipient configuration'){
	    	    	    								modal.alerts.push({msg:thirdNoti.third.completeName + " - " + $filter('translate')('global.errors.noCheckNotifiableRecipientConfiguration')});
	    	            			    			} else if(data[key] == 'Error call to PDI') {
	    	    	    								modal.alerts.push({msg:thirdNoti.third.completeName + " - " + $filter('translate')('global.errors.errorCheckNotifiableRecipients')});
	    	            			    			} else {
	    	            			    				modal.alerts.push({msg:thirdNoti.third.completeName + " - " + data[key]});
	    	            			    			}
	    	            	                    });
	    	                				}
	    	                			} else {
	    	                				closeModal();
	    	                			}
	    	                		}).catch(function (error) {
	    	                        	console.error(error);
	    	                        });
	    	                	} else {
	    	                		closeModal();
	    	                	}
	    	                }
	                	}
	                }
            	}
            });
            
            $scope.$on('update_documents_lists', function (event, args) {
            	if(vm.origin == 'tram'){
            		if(args.notification && args.notification.data && args.notification.data.dossierTransaction) {
                		vm.config.updateDocumentsFunction(args.notification.data.dossierTransaction);
                	}
            	} else {
            		if(args.notification && args.notification.data && args.notification.data.registerEntry) {
                		vm.config.updateDocumentsFunction(args.notification.data.registerEntry);
                	}
            	}
            });

            this.$onInit = function () {
                vm.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
                vm.notificationModule = true;
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality){
                    vm.notificationModule = $rootScope.app.configuration.show_notification_functionality.value;
                }
                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.notifications';
                }

                if(vm.search) {
                    vm.search.origin = 'reg';
                    vm.search.type = 'NotificationThird';

                    vm.search.searchFunc = function(val) {
                        var added = $linq(vm.content).select(vm.search.addedSelect).toArray();
                        
                        var filter = vm.searchByEntryType ?  { entryType: vm.entryType } : {};

                        var additionalFilter = vm.search.additionalFilter ? vm.search.additionalFilter : {};
                        additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                        additionalFilter.added = added;
                        additionalFilter.langColumn = Language.getActiveColumn();
                        additionalFilter.searchBox = val.val;
                        additionalFilter.isAdvanced = true;
                        if(vm.origin == 'annexa-box-object-remittance') {
                        	additionalFilter.checkNotTypeSicer = true;
                        }

                        return RestService.filterData(vm.search.origin, vm.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
                            .then(function (data) {
                                var response = [];

                                _.forEach(data.data.content, function(value, key) {
                                    response.push({
                                        id: value.id,
                                        object: value.notification,
                                        value: vm.search.processSearch(value)
                                    });
                                });

                                response = new SortedArray(response, 'value').sort();

                                return response;
                            });

                    }


                    vm.search.processSearch = function(object) {
                        return object.notification.registerEntry.entryNumber + (object.notification.extract ? ' ' + object.notification.extract : '');
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                        	var newContent = [];
                        	if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                        		_.forEach(vm.search.searchedValue.object, function(obj){
                        			newContent.push(obj);
                        		});
                        	}else if(vm.search.searchedValue.object.notification) {
                        		newContent.push(vm.search.searchedValue.object.notification);
                        	}else {
                        		newContent.push(vm.search.searchedValue.object);
                        	}

                    		_.forEach(newContent, function(obj){
                    			vm.content.push(obj);
                            });
                    		  
                    		vm.search.searchedValue = { id: -1, value: '' };
                            if(vm.search.saveSearch ) {
                                vm.search.saveSearch(newContent);
                            }
                        }
                    }

                    vm.search.advancedSearch = function() {
                    	
                    	$scope.notificationStates = angular.copy(NotificationFactory.notificationGlobalStatus);
                        $scope.notificationStates.unshift({
                            id: '',
                            icon: 'minus',
                            style: 'text-black',
                            name: $filter('translate')('global.literals.all'),
                            orderStatus: 0
                        });
                        
                        $scope.headerIconNotificationGlobalStatus = function(icon) {
                            if($scope.notificationStates && $scope.notificationStates.length > 0 && $scope.filterData.state) {
                                var status = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
                                if(status) {
                                    if(icon) {
                                        return status.icon;
                                    } else {
                                        return status.style;
                                    }
                                }
                            }
                            if(icon) {
                                return 'minus';
                            } else {
                                return 'false';
                            }
                        }
                        
                        $scope.setNotificationGlobalTypeDT = function(state){
                            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
                            if($scope.tableDefinition){ 
                            	$scope.changeStatusFromHeader = true;
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }else{
                                var scopeAux = angular.element('#tableAdvancedSearchNotifications').scope();
                                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                                    scopeAux.tableDefinition.reloadInternalData(true, true);
                                }
                            }
                        }
                        
                    	var notificationGlobalStatusTitle = function() {
                            var content = '';
                 
                            content += '<div>';
                            content += '    <div class="btn-group dropdown">';
                            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
                            content += '            <span class="fa-stack">';
                            content += '                <i class="fa fa-{{headerIconNotificationGlobalStatus(true)}} {{headerIconNotificationGlobalStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
                            content += '            </span>';
                            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
                            content += '        </button>';
                            content += '        <div class="dropdown-menu">';
                            content += '            <a class="dropdown-item" ng-repeat="state in notificationStates | orderBy:\'orderStatus\'" ng-click="setNotificationGlobalTypeDT(state);">';
                            content += '                <span class="fa-stack">';
                            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
                            content += '                </span>';
                            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
                            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
                            content += '            </a>';
                            content += '        </div>';
                            content += '    </div>';
                            content += '</div>';

                            return content;
                        }
                        
                    	var notificationGlobalStatusRender = function(data, type, full, meta) {
                            var content = '';

                            if($scope.notificationStates && $scope.notificationStates.length > 0) {
                                var state = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                                if(state) {
                                    content += '<div class="text-center">';
                                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                                    content += '    </span>';
                                    content += '</div>';
                                }
                            }

                            return content;
                        }
                    	
                    	var renderNotificationLastStatus = function (data, type, full, meta) {
                            var orderedByDateStatuses = $linq(data).orderBy("x => x.createdDate").toArray();

                            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
                                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
                        };

                        var getAddedFunction = function() {
                        	var added = $linq(vm.content).select("x => x.id").toArray();

                        	if (vm.entityId) {
                            	added.push(vm.entityId);
                            }
                        	
                        	return added;
                        }
                        
                        var advancedSearchModal = {
                            title: 'global.literals.advanced_search_registerEntry',
                            size: 'modal-lg',
                            filter: [
                                { id: 'extract', type: 'text', order: 1, label: 'global.literals.extract', callAux: true, userChoosen: false},
                                { id: 'notificationType', type: 'select', order: 2, label: 'global.literals.notificationTypes', dataType: 'LOCAL', data: GlobalDataFactory.notificationTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: false},
                                { id: 'globalStatus', type: 'select', order: 3, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: NotificationFactory.notificationGlobalStatus, nameProperty: 'name', addAll: true, callAux: true, userChoosen: false},
                                { id: 'status', type: 'select', order: 4, label: 'global.literals.particularStatus', dataType: 'LOCAL', data: GlobalDataFactory.notificationStatuses, nameProperty: Language.getActiveColumn(), addAll: true, callAux: true, userChoosen: true},
                                { id: 'entryNumber', type: 'text', order: 5, label: 'global.reg.datatable.numberOutput', callAux: true, userChoosen: false},
                                { id: 'dossierNumber', type: 'text', order: 6, label: 'global.literals.numExp', callAux: true, userChoosen: true},
                                { id: 'thirdName', type: 'text', order: 7, label: 'global.literals.receivers', callAux: true, userChoosen: false},
                                { id: 'userCreator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true, userChoosen: true},
                                { id: 'createdDate', type: 'dateRange', order: 9, label: 'global.literals.creation_date', callAux:true, userChoosen: true},
                                { id: 'originEntryNumber', type: 'text', order: 10, label: 'global.literals.originEntryInputNumber', callAux:true, userChoosen: true},
                                { id: 'originRegisterOrgan', type: 'text', order: 11, label: 'global.literals.originEntryInputOrgan', callAux:true, userChoosen: true}
                            ],
                            tableDefinition: {
                                id: 'tableAdvancedSearchNotifications',
                                origin: 'reg',
                                objectType: 'NotificationThird',
                                sortName: 'createdDate',
                                sort: [[3, 'asc']],
                                columns: [
                                    { id: 'notification.globalStatus', width: '20px',  title: notificationGlobalStatusTitle(), render: notificationGlobalStatusRender, sortable: false },
                                    { id: 'notification.notificationType.'+ Language.getActiveColumn(), width: '150px', column: new DatabaseTranslatedColumn($filter, 'global.literals.notificationTypes', Language.getActiveColumn()) },
                                    { id: 'createdDate', width: '120px', column: new DateTimeColumn($filter, 'global.literals.creation_date')},
                                    { id: 'statuses', title: $filter('translate')('global.literals.state'), render: renderNotificationLastStatus, sortable: false},
                                    { id: 'notification.extract', width: '120px', title: $filter('translate')('global.literals.extract'), className: 'pre-line'},
                                    { id: 'notification.registerEntry.registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') },
                                    { id: 'notification.registerEntry.id', width: '120px', column: new UIRouterStateColumn($filter, 'global.reg.datatable.numberOutput','annexa.reg.output.entries.edit({output:\'[data]\'})', undefined, 'var number = full.notification.registerEntry.entryNumber; number;')},
                                    { id: 'third', column: new ThirdColumn($filter, 'global.literals.receivers')}
                                ],
                                noLoading: true,
                                skipFirstSearch: true,
                                callOrigin: 'box',
                                callAuxToAdd: { added: getAddedFunction(),  isAdvanced:true, checkNotTypeSicer: vm.origin == 'annexa-box-object-remittance' ? true : false}
                            },
                            multiple: true
                        }
                        if(vm.searchByEntryType){
                            advancedSearchModal.tableDefinition.callToAdd = {entryType: vm.entryType };
                        }
                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASNotifications', advancedSearchModal);
                    }

                }

                if(!vm.hiddeNew) {
                	if(!vm.new) {
                        vm.new = {};
                    }
	                vm.new.newFunc = function() {
	                    var archiveClassifications = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
	                    var newModal =  NotificationFactory.notificationNewModal(
	                        vm.config.thirdBox ? angular.copy(vm.config.thirdBox.content) : undefined,
	                        vm.config.documentBox ? angular.copy(vm.config.documentBox.content) : undefined,
	                        vm.config.dossier ? angular.copy(vm.config.dossier) : undefined,
	                        vm.config.registerEntry ? angular.copy(vm.config.registerEntry) : undefined,
	                        undefined, undefined, undefined, archiveClassifications
	                    );
	
	                    if(vm.config.dossierTransactionId) {
	                    	newModal.dossierTransactionId = vm.config.dossierTransactionId;
	                    }
	                    
	                    var submitNewNotification = function () {
	                        NotificationFactory.getNewNotifications(this, newModal, vm.isModal);
	                    }
	
	                    newModal.submitModal = submitNewNotification;
	
	                    AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', newModal);
	                };
                }
            };
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@',
            isModal:'=?',
            hiddeNew:'=?'
        }
    })
    .component('annexaBoxNotificationsRender', {
        templateUrl: './components/reg/annexa-box-notifications/annexa-box-notifications-render.html',
        controller: ['$state', 'NotificationFactory', 'Language', 'AnnexaFormlyFactory', 'DialogsFactory', function($state, NotificationFactory, Language, AnnexaFormlyFactory, DialogsFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.notificationGlobalStatus = NotificationFactory.notificationGlobalStatus;
            console.log(typeof NotificationFactory);

            vm.isDiligenceWindow = function() {
                return $state.current.name == 'annexa.reg.input.diligences.edit';
            }
            
            vm.viewNotification = function (notification) {
                var notificationViewModal = NotificationFactory.notificationViewModal(notification, vm.origin);

                AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);
            }
            
            vm.lastStatusUpdateNotifications = function(thirds) {
            	var content = '';
            	if(thirds) {
            		var select = []
            		_.forEach(thirds, function(value){
		    			select = $linq(select).union(value.statuses).toArray();
                    });
            		if (select) {
			    		var contentNoParce = $linq(select).orderByDescending("x => x.createdDate").first();
			    		if (contentNoParce) {
			    			var content =  contentNoParce.createdDate;
			    		}
            		}
            	}
            	return content;
            }
            
            vm.renderNotificationLastStatus = function (data) {
				if(data) {
		    		var select = []
		    		_.forEach(data, function(value){
		    			select = $linq(select).union(value.statuses).toArray();
                    });
				}
				if (select) { 
					var orderedByDateStatuses = $linq(select).orderBy("x => x.createdDate").toArray();
				}
				
	            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
	                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
	        };

            vm.getIconClassGlobalStatus = function (notification) {
                switch (notification.globalStatus) {
                    case 'PENDING':
                        return 'text-warn';
                    case 'SEND':
                        return 'text-info';
                    case 'COMPLETE':
                        return 'text-success';
                    case 'ERROR':
                        return 'text-danger';
                }

                return '';
            }

            vm.delete = function (index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteNotification')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxNotificationsDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function(data) {
                    //Empty
                })
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })