angular
    .module('annexaApp')
    .component('annexaBoxRegisterentryPaginated', {
        templateUrl: './components/reg/annexa-box-registerentry-paginated/annexa-box-registerentry-paginated.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'RegFactory', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout', '$q','TramNewFactory', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, RegFactory, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout, $q, TramNewFactory) {
            var vm = this;
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASRegisterEntries') {
                    if(!vm.box || !vm.box.searchByEntryType || (vm.box.searchByEntryType && args.modal && args.modal.tableDefinition && args.modal.tableDefinition.callToAdd && args.modal.tableDefinition.callToAdd.entryType && vm.box && vm.box.tableDefinition && vm.box.tableDefinition.entryType == args.modal.tableDefinition.callToAdd.entryType)){
                    	var addedObjects = [];
                        var id = undefined;
                        _.forEach(args.selected, function (item) {
                    		if(id){
                        		id =id+','+item.id;
                        	}else{
                        		id = item.id;
                        	}
                        	addedObjects.push(item);
                        });
                        
                        if(addedObjects && addedObjects.length > 0){
                        	vm.box.search.searchedValue = { id: id, object: addedObjects };
                            vm.box.search.completeAdd();
                        }
                    	
                        args.modal.close();
                    }
                }
            });

            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
					if(vm.box.tableDefinition.entryType){
						vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(
		                    vm.box.permissions,
		                    vm.box.tableDefinition.entryType == 'INPUT' ? 'new_input_entry_register' : 'new_output_entry_register',
		                    vm.box.tableDefinition.entryType == 'INPUT' ? ['view_input_register', 'diligence_input_register','view_all_diligences','view_all_input_register'] : ['view_output_register', 'view_all_output_register'],
		                    vm.box.isEdit
		                );
					}else{
						vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(
			                    vm.box.permissions,
			                    ['new_input_entry_register','new_output_entry_register'],
			                    ['view_input_register', 'diligence_input_register','view_all_diligences','view_all_input_register', 'view_output_register', 'view_all_output_register'],
			                    vm.box.isEdit
			                );
					}
					if(!vm.box.labels){
						vm.labels = {
							docs: '',
							work: '',
							internal: '',
							paperBin: '',
							documentTransactionActiveTab: 'DOCUMENT'
						}
					}
					if(!vm.box.counters){
						vm.counters = {
							docs: 0,
							work: 0,
							internal: 0,
							paperBin: 0
						}
					}
	                vm.regInputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.input'").toArray();
	
	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.literals.assentaments';
	                }
	
	                if(vm.box.search) {
	                    vm.box.search.origin = 'reg';
	                    vm.box.search.type = 'RegisterEntry';
	
	                    vm.box.search.searchFunc = function(val) {
	                        var filter = vm.box.searchByEntryType ?  { entryType: vm.box.tableDefinition.entryType } : {};
	
							var additionalFilter = vm.box.search.additionalFilter ? vm.box.search.additionalFilter : {};
	                        additionalFilter.loggedUser = $rootScope.LoggedUser.id;
	                        additionalFilter.paginated = true;
	                        additionalFilter.paginatedObject = vm.box.tableDefinition.objectIdToSelectType;
	                        additionalFilter.paginatedObjectId = vm.box.tableDefinition.objectIdToAdded;
	                        additionalFilter.langColumn = Language.getActiveColumn();
	                        additionalFilter.searchBox = val.val;
	                        additionalFilter.isAdvanced = true;
	
	                        return RestService.filterData(vm.box.search.origin, vm.box.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
	                            .then(function (data) {
	                                var response = [];
	
	                                _.forEach(data.data.content, function(value, key) {
	                                    response.push({
	                                        id: value.id,
	                                        object: JSOG.decode(value),
	                                        value: vm.box.search.processSearch(JSOG.decode(value))
	                                    });
	                                });
	
	                                response = new SortedArray(response, 'value').sort();
	
	                                return response;
	                            });
	
	                    }
	
	
	                    vm.box.search.processSearch = function(object) {
	                        var value = object.entryType == 'INPUT' ? '<i class="fa fa-sign-in"></i> ' : '<i class="fa fa-flip-horizontal fa-sign-out"></i> ';
	
	                        value += object.entryNumber + (object.extract ? ' ' + object.extract : '');
	
	                        return value;
	                    }
	
	                    if(!vm.box.search.completeAdd) {
	                        vm.box.search.completeAdd = function() {
	                        	var newContent = [];
	                        	if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                        		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                        			newContent.push(JSOG.decode(obj));
	                        		});
	                        	}else{
	                        		newContent.push(JSOG.decode(vm.box.search.searchedValue.object));
	                        	}
	  
	                    		vm.box.search.searchedValue = { id: -1, value: '' };
	                            if(vm.box.search.saveSearch ) {
	                                vm.box.search.saveSearch(newContent);
	                            }
	                        }
	                    }
	
	                    vm.box.search.advancedSearch = function() {
	                        var renderEntryType = function(data, type, full, meta) {
	                            var content = '';
	
	                            if(data == 'INPUT') {
	                                content += '<i class="fa fa-sign-in" title="{{ \'global.literals.input\' | translate }}"></i>';
	                            } else {
	                                content += '<i class="fa fa-sign-out" title="{{ \'global.literals.output\' | translate }}"></i></span>';
	                            }
	
	                            return content;
	                        }
	                        
	                        var renderThirds = function(data, type, full, meta){
	                            var content = '';
	                        	var tooltip = '';
	                            var principal = '';
	                            var numThirds = 0;
	                            if(data){
	                                if(data.length > 1) {
	                                    _.forEach(data, function(third){
	                                        if(third.principal) {
	                                           if(third.third.thirdType != 'PHISICAL') {
	                                        	   principal = third.third.corporateName;
		                                        } else {
		                                        	principal = third.third.name + ((third.third.surname1Particles) ? ' ' + third.third.surname1Particles : '') + ' ' + third.third.surename1;
		                                            if(third.third.surename2){
		                                            	principal += ((third.third.surname2Particles) ? ' ' + third.third.surname2Particles : '') + ' ' + third.third.surename2 ;
		                                            }
		                                        }
	                                        } else {
	                	               			if(numThirds > 0) {
	                	               				tooltip += ' | ';
	                	               			}
	                                           if(third.third.thirdType != 'PHISICAL') {
	                                               tooltip += third.third.corporateName;
		                                        } else {
		                                            tooltip += third.third.name + ((third.third.surname1Particles) ? ' ' + third.third.surname1Particles : '') + ' ' + third.third.surename1;
		                                            if(third.third.surename2){
		                                                tooltip += ((third.third.surname2Particles) ? ' ' + third.third.surname2Particles : '') + ' ' + third.third.surename2 ;
		                                            }
		                                        }
	                                           numThirds++;
	                                        }
	                                   });
	                                   content += '<b uib-tooltip="' + tooltip + '" tooltip-placement="top" aria-label="' + tooltip + '"">';
	                                   content += principal;
	                                   content += '</b>';
	                                } else if(data[0] && data[0].third) {
	                                    if(data[0].third.thirdType != 'PHISICAL') {
	                                    	content = data[0].third.corporateName;
	                                    } else {
	                                    	content = data[0].third.name + ((data[0].third.surname1Particles) ? ' ' + data[0].third.surname1Particles : '') + ' ' + data[0].third.surename1;
	                                        if(data[0].third.surename2){
	                                        	content += ((data[0].third.surname2Particles) ? ' ' + data[0].third.surname2Particles : '') + ' ' + data[0].third.surename2 ;
	                                        }
	                                    }
	                                }
	                            }
	                            return content;
	                        }
	
	                        var advancedSearchModal = {
	                            title: 'global.literals.advanced_search_registerEntry',
	                            size: 'modal-lg',
	                            filter: [
	                                { id: 'entryNumber', type: 'text', order: 0, label: ((vm.box.searchByEntryType && vm.box.tableDefinition.entryType == 'OUTPUT') ? 'global.literals.numberOuput' : 'global.literals.number')},
	                                { id: 'extract', type: 'text', order: 1, label: 'global.literals.extract' },
	                                { id: 'procedureName', type: 'text', order: 0, label: 'global.literals.procedure', callAux: true, userChoosen: true },
	                                { id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.numExp', callAux: true, userChoosen: true },
	                                { id: 'dataReg', type: 'dateRange', order: 2, label: 'global.literals.regFrom', callAux: true, userChoosen: true },
	                                { id: 'dataPres', type: 'dateRange', order: 3, label: 'global.literals.presFrom', callAux: true, userChoosen: true },
	                                { id: 'entryDocumentType', type: 'select', order: 4, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: true },
	                                { id: 'entryClassification', type: 'select', order: 5, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: true },
	                                { id: 'representant', type: 'text', order: 6, label: 'global.literals.representant', callAux: true, userChoosen: true },
	                                { id: 'section', type: 'select-tree', order: 13, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: true },
	                                { id: 'diligenceProfile', type: 'select-multiple', order: 14, label: 'global.literals.profile', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.allProfiles,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true },
	                                { id: 'historical', type: 'checkbox', order: 15, label: 'global.literals.showhistorical', callAux: true, userChoosen: false }
	                            ],
	                            tableDefinition: {
	                                id: 'tableAdvancedSearchDocuments',
	                                origin: 'reg',
	                                objectType: 'RegisterEntry',
	                                sortName: 'entryNumber',
	                                sort: [[2, 'asc']],
	                                columns: [
	                                    { id: 'entryType', width: '40px', title: $filter('translate')('global.literals.type'), render: renderEntryType },
	                                    { id: 'entryNumber', width: '100px', title: $filter('translate')('global.literals.number') },
	                                    { id: 'registerDate', width: '90px', column: new DateTimeColumn($filter,'global.reg.datatable.registerDate') },
	                                    { id: 'presentationDate', width: '90px', column: new DateColumn($filter,'global.literals.presFrom') },
	                                    { id: 'registerEntryChannel.'+ Language.getActiveColumn(), width: '50px', title: $filter('translate')('global.literals.channel') },
	                                    { id: 'extract', title: $filter('translate')('global.literals.extract'), sortable: false },
	                                    { id: 'registerEntryOffice.' + Language.getActiveColumn(), title: $filter('translate')('global.literals.office') },
	                                    { id: 'thirds', title: $filter('translate')('global.literals.thirds'), render: renderThirds, sortable: false }
	                                ],
	                                noLoading: true,
	                                skipFirstSearch: true,
	                                callAuxToAdd: { paginated:true, paginatedObject:vm.box.tableDefinition.objectIdToSelectType, paginatedObjectId:vm.box.tableDefinition.objectIdToAdded, isAdvanced:true}
	                            },
	                            multiple: ((vm.box.origin === 'thirds')?false:true)
	                        }
	                        if(vm.box.searchByEntryType){
	                            advancedSearchModal.tableDefinition.callToAdd = {entryType: vm.box.tableDefinition.entryType };
	                        }
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASRegisterEntries', advancedSearchModal);
	                    }
	
	                }
	
	                if(vm.box.new) {
	                    if(!vm.box.new.newFunc) {
	                        vm.box.new.newFunc = function() {
	                            var submitNewRegisterEntry = function() {
	                                $timeout(function () {
	                                    angular.element('#annexaForm-new-registerentry').trigger('click');
	                                });
	                            }
	
	                            var openSelectReceiversAndDocumentsModal = function (thirds, documents, dossier, fromDossier) {
	                            	var fixedDocuments = documents;
	                            	if(vm.box.tableDefinition.entryType == 'OUTPUT') {
	                            		fixedDocuments = [];
	                            		_.forEach(documents, function(doc){
	                            			if(doc.type.includeRegisterEntry){
	                            				fixedDocuments.push(doc);
	                            			}
	                            		})
	                            	}
	                                var selectReceiversAndDocumentsModal = {
	                                    title: 'global.literals.edit_receiver_and_documents_modal_title',
	                                    size: 'modal-md',
	                                    fieldsDefinition: [
	                                        { type: 'field', id: 'thirds', fieldType: 'component', componentType: 'annexa-select-thirds',
	                                            data: {
	                                                title: vm.box.tableDefinition.entryType == 'INPUT' ? 'global.literals.origin' : 'global.literals.destiny',
	                                                thirds: thirds,
	                                                dossier: dossier,
	                                                hideAddresses: true
	                                            }
	                                        },
	                                        { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
	                                            data: {
	                                                title: 'global.literals.documents',
	                                                documents: fixedDocuments
	                                            }
	                                        }
	                                    ],
	                                    alerts: [],
	                                    submitModal: function() {
	                                    }
	                                }
	
	                                var submitSelectReceiversAndDocumentsModal = function() {
	                                    var selectedThirds = $linq(this.fieldsDefinition[0].data.thirds).where("x => x.selected").toArray();
	                                    var selectedDocumentsAux = angular.copy($linq(this.fieldsDefinition[1].data.documents).where("x => x.selected").toArray());
	                                    var selectedDocuments = [];
	                                    var tramClassification = undefined;
	                                    if(selectedDocumentsAux && selectedDocumentsAux.length > 0){
	                                    	_.forEach(selectedDocumentsAux, function(doc){
	                                    		selectedDocuments.push({document: doc, relationType:'DOCUMENT'});
	                                    	});
	                                    }
	                                    selectReceiversAndDocumentsModal.close();
	                                    if(vm.box.config && vm.box.config.dossier && vm.box.config.dossier.tramClassification) {
	                                    	tramClassification = vm.box.config.dossier.tramClassification;
	                                    }
	                                    RegFactory.newRegisterEntryModal(vm.box.tableDefinition.entryType, selectedThirds, selectedDocuments, [vm.box.config.dossier], submitNewRegisterEntry, fromDossier, tramClassification);
	                                }
	
	                                AnnexaFormlyFactory.showAnnexaFormModal('modalSRDNewRegisterEntry', selectReceiversAndDocumentsModal, submitSelectReceiversAndDocumentsModal);
	                            }
	
	                            if(vm.box.config.dossier) {
									var promises = [];
									if(vm.box.config.dossier.showThirdInfo && vm.box.config.dossier.showThirdInfo != 'ALL') {
										promises.push(TramNewFactory.getDossierThirdsAnon(vm.box.config.dossier.id));
									} else {
										promises.push(TramNewFactory.getDossierThirds(vm.box.config.dossier.id));
									}
				                	promises.push(TramNewFactory.getDossierDocuments(vm.box.config.dossier.id));
					                $q.all(promises).then(function(data) {
		                                openSelectReceiversAndDocumentsModal(
		                                    ((data && data[0])?JSOG.decode(data[0]):[]),
		                                    ((data && data[1])?JSOG.decode(data[1]):[]),
		                                    vm.box.config.dossier,
		                                    true
		                                );
									}).catch(function() {
				                    })					
	                            } else {
	                                RegFactory.newRegisterEntryModal(vm.box.tableDefinition.entryType, undefined, undefined, undefined, submitNewRegisterEntry);
	                            }
	                        }
	                    }
	
	                    $scope.$on('createdRegisterEntry', function (event, args) {
	                        if(args.id == 'modalNewRegisterEntry' && args.data.entryType == vm.box.tableDefinition.entryType) {
	                            if(vm.box.search.saveSearch) {
	                                vm.box.search.saveSearch(args.data);
	                            }
	                        }
	                    })
	                }
				}
            }
        }],
        bindings: {
            box: '='
        }
    })
