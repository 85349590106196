angular
    .module('annexaApp')
    .component('annexaBoxObjectRemittance',{
        templateUrl: function($element, $attrs) {
            return (($attrs.isEdit == 'false' || $attrs.isEditModal == 'false') ?
                './components/reg/annexa-box-object-remittance/annexa-box-object-remittance.html' :
                './components/reg/annexa-box-object-remittance/annexa-box-object-remittance-edit.html')
        },

        controller: ['RegFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', '$compile', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'ThirdFactory', 'AnnexaBoxDossierFactory', 'NotificationFactory', 'TramNewFactory', 'AnnexaModalFactory', 'TerritoryFactory', 'DccumentsFactory', 'AuditFactory', 'RegModals', '$stateParams', 'RemittanceFactory',
        	function (RegFactory, $filter, $rootScope, ErrorFactory, $state, $scope, $compile, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, ThirdFactory, AnnexaBoxDossierFactory, NotificationFactory, TramNewFactory, AnnexaModalFactory, TerritoryFactory, DccumentsFactory, AuditFactory, RegModals, $stateParams, RemittanceFactory) {

            //region General

            var vm = this;
            
            vm.numberNotificationsBox =  {value:0};
            
            vm.languageColumn = Language.getActiveColumn();
            vm.loggedUser = $rootScope.LoggedUser;
            vm.selectableProfiles = notifications = $linq(vm.loggedUser.userProfiles).select("x => x.profile").toArray();
            
            vm.calculateCounters = function(counter, number){
            	if(vm.remittance){
            		if(!counter || counter && counter === 'remittanceNotifications'){
            			vm.numberNotificationsBox.value = number;
					}
            	}
            }
            vm.canEdit = false;
            if(vm.isEdit && vm.remittance.status == 'OPEN' && !AnnexaPermissionsFactory.havePermission('manage_all_remittance')) {
            	vm.canEdit = AnnexaPermissionsFactory.haveProfile(vm.remittance.profile);
            } else if(vm.isEdit && vm.remittance.status == 'OPEN') {
            	vm.canEdit = true;
            } 

            //endregion

            //region Form
            vm.newRemittanceFormFields = [
            	{ type: 'field', id: 'remittanceType', fieldType: 'select', data: GlobalDataFactory.remittanceTypes, colClass: 'col-sm-12', required: true, label: 'global.remittance.literals.remittanceType', isReadOnly:vm.canEdit, editPermissions: ['manage_remittance', 'manage_all_remittance']},
            	{ type: 'field', id: 'profile', fieldType: 'select', data: vm.selectableProfiles, origin: 'Remittance', colClass: 'col-sm-12', required: true, label: 'global.remittance.literals.profile', isReadOnly:vm.canEdit, editPermissions: ['manage_remittance', 'manage_all_remittance']}
            ];

            vm.submit = function() {
                this.form.$setSubmitted();

                if(this.form.$valid) {                	
                    var newRemittance = {
                        profile: this.model.profile ? (this.model.profile.id ? { id: this.model.profile.id } : { id: this.model.profile }) : undefined,
                        remittanceType: this.model.remittanceType ? (this.model.remittanceType.id ? { id: this.model.remittanceType.id } : { id: this.model.remittanceType }) : undefined,
                        remittanceNotifications: [],
                    }

                    _.forEach(vm.notificationsBox.content, function (item) {
                        if(item) {
                        	newRemittance.remittanceNotifications.push({notification: {id:item.id}});
                        }
                    });

                    RemittanceFactory.createRemittance(newRemittance)
                        .then(function(data) {
                        	$state.transitionTo('annexa.reg.output.remittances');
                        }).catch(function (error) {
                            $rootScope.$broadcast('dummy');
                            vm.alerts.push({msg: ErrorFactory.getErrorMessage('register', 'new', error)});
                    })
                }
            }

            vm.alerts = [];

            //endregion

            //region Notifications Box

            //endregion

            //region RegisterEntry Methods

            vm.saveRemittance = function() {
                $rootScope.$broadcast('submitForm', { id: 'new-remittance' });
            }

            vm.cancelRemittance = function() {
            	$state.transitionTo('annexa.reg.output.remittances');
            }
            
            vm.checkUpdateRegisterEntry = function(val, prop) {
            	if(vm.lastAudit && vm.registerEntry && vm.registerEntry.id) {
            		vm.checkLastAuditConfirmation(val, prop);
            	} else {
            		if(vm.registerEntry && vm.registerEntry.id) {
            			vm.checkLastAuditConfirmation(val, prop);
            		} else {
                		vm.updateRegisterEntry(val, prop);
            		}
            	}
            }

            vm.updateRemittance = function(val, prop) {
            	vm.remittance[prop] = val;

                RestService.update('./api/reg/remittance/' + vm.remittance.id, vm.remittance)
                    .then(function (data) {
                    }).catch(function (error) {
                        console.error(error);
                });
            }
            
            vm.closeRemittance = function () {
                DialogsFactory.confirm('global.remittance.literals.closeRemittance', 'global.remittance.literals.closeRemittanceMsg')
	                .then(function (data) {
						RemittanceFactory.closeRemittance(vm.remittance.id).then(function(data) {
							$state.reload();
	                    }).catch(function (error) {
	                    	console.error(error);
	                    });
	                }).catch(function(data) {});
            }
            
            vm.endRemittance = function () {
            	if(vm.remittance.electronicFile && vm.remittance.electronicFile != '') {
            		DialogsFactory.confirm('global.remittance.literals.endRemittance', 'global.remittance.literals.endRemittanceMsg')
		                .then(function (data) {
							RemittanceFactory.endRemittance(vm.remittance.id).then(function(data) {
								$state.reload();
		                    }).catch(function (error) {
		                    	console.error(error);
		                    });
		                }).catch(function(data) {});
            	} else {
            		DialogsFactory.notify($filter('translate')('global.remittance.literals.endRemittanceFileNotExistMsg'));
            	}
            }
            
            vm.createElectronicFile = function() {
            	if(vm.remittance.electronicFile && vm.remittance.electronicFile != '') {
            		DialogsFactory.notify($filter('translate')('global.remittance.literals.createElectronicFileExistMsg'));
            	} else {
            		DialogsFactory.confirm('global.remittance.literals.createElectronicFile', 'global.remittance.literals.createElectronicFileMsg')
		                .then(function (data) {
							RemittanceFactory.generateElectronicFile(vm.remittance.id).then(function(data) {
								$state.reload();
		                    }).catch(function (error) {
		                    	console.error(error);
		                    	DialogsFactory.error(error.data.message);
		                    });
		                }).catch(function(data) {});
            	}
            }
            
            vm.printRemittanceDoc = function () {
				RemittanceFactory.printNotificationsDocument(vm.remittance.id).then(function(data) {
					var wnd = window.open(data, '_blank');
                	wnd.addEventListener('DOMContentLoaded', function(){wnd.print();});
                }).catch(function (error) {
                	console.error(error);
                	DialogsFactory.error(ErrorFactory.getErrorMessage('remittance', 'document', error.data));
                });
			}
            
            vm.printNotificationsListDocument = function () {
				RemittanceFactory.printNotificationsListDocument(vm.remittance.id).then(function(data) {
					var wnd = window.open(data, '_blank');
                	wnd.addEventListener('DOMContentLoaded', function(){wnd.print();});
                }).catch(function (error) {
                	console.error(error);
                	DialogsFactory.error(ErrorFactory.getErrorMessage('remittance', 'document', error.data));
                });
			}

            $scope.$on('AnnexaProfileEditUpdated', function (event, args) {
                if(args.profile) {
                    vm.checkUpdateRegisterEntry(args.profile, 'profile');
                }
            });

            //endregion

            this.$onInit = function() {
            	
            	vm.newPerm = ['manage_remittance', 'manage_all_remittance'];
                vm.editPerm = ['manage_remittance', 'manage_all_remittance'];
                vm.viewPerm = 'view_remittance';
            	
                vm.numberNotificationsBox.value = vm.remittance ?  vm.remittance.notificationsCount : 0;
                
                vm.notificationsBox = {
                    permissions: { new: vm.newPerm, view: vm.newPerm },
                    search: { placeholder: 'global.literals.placeholder_search_notification', advanced: true, saveSearch: undefined },
                    content:  vm.remittance ?  vm.remittance.remittanceNotifications : [],
                    new: {},
                    config: {},
                    origin: 'annexa-box-object-remittance',
                    isModal:vm.isModal,
                    hiddeNew:true
                }

                //endregion
            }
        }],
        bindings: {
            isEdit: '=',
            redirectToList: '=',
            remittance: '=?',
            diligenceId: '@?',
            preloadBoxes: '=?',
            isModal: '=',
            fromDossier: '=',
            tramClassification: '=?'
        }
    })
    .component('annexaBoxObjectRemittanceEditHeader',{
        templateUrl: './components/reg/annexa-box-object-remittance/annexa-box-object-remittance-edit-header.html',
        require: {
            registerEntryComponent: '^^annexaBoxObjectRemittance'
        },
        controller:['$rootScope', '$filter', '$state', 'Language', 'RemittanceFactory', function($rootScope, $filter, $state, Language, RemittanceFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            //endregion
            
            this.$onInit = function() {
            	if(RemittanceFactory.remittanceGlobalStatus && RemittanceFactory.remittanceGlobalStatus.length > 0 && 
            			vm.remittance && vm.remittance.status){
            		vm.state = $linq(RemittanceFactory.remittanceGlobalStatus).singleOrDefault(undefined, "x => x.id == '"+vm.remittance.status+"'");
           		}
            }
        }],
        bindings: {
        	remittance: '=?'
        }
    })
	.component('annexaBoxObjectRemittanceNotifications',{
        templateUrl: './components/reg/annexa-box-object-remittance/annexa-box-object-remittance-notifications.html',
        require: {
            remittanceComponent: '^^annexaBoxObjectRemittance'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 'RemittanceFactory', 'ErrorFactory',
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http, RemittanceFactory, ErrorFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.notificationBox = {};
        
			var generateNotificationBox= function(){
				if(!vm.notificationTableDefinition){
					vm.notificationTableDefinition = {};
				}
				vm.viewNotification = function (notificationId) {
					if(notificationId && $scope.tableObjects && $scope.tableObjects[notificationId].notification){
		                var notificationViewModal = NotificationFactory.notificationViewModal($scope.tableObjects[notificationId].notification, undefined, vm.remittanceComponent.remittance.status != 'COMPLETE');
		                AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);
					}
	            }
				
				vm.deleteNotification = function (notificationId) {
	                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteNotification')
	                    .then(function (data) {
	    					RemittanceFactory.deleteNotification(notificationId).then(function(data) {
	                            if(vm.notificationTableDefinition && vm.notificationTableDefinition.reloadInternalData) {
	    							vm.notificationTableDefinition.reloadInternalData(true, true);
	    						}
	                            vm.remittanceComponent.calculateCounters('remittanceNotifications', data);
	                        }).catch(function (error) {
	                        	console.error(error);
	                        });
	                    }).catch(function(data) {
	                    //Empty
	                });
	            }
				
				vm.printDoc = function (notificationId) {
					RemittanceFactory.printNotificationDocument(notificationId).then(function(data) {
						var wnd = window.open(data, '_blank');
	                	wnd.addEventListener('DOMContentLoaded', function(){wnd.print();});
                    }).catch(function (error) {
                    	console.error(error);
                    	DialogsFactory.error(ErrorFactory.getErrorMessage('remittance', 'document', error.data));
                    });
				}
				
				var addNotificationSave = function(notifications) {
					if(!Array.isArray(notifications)){
						if(notifications.searchedValue) {
							notifications = [notifications.searchedValue.object];
						} else {
							notifications = [notifications];
						}
					} else {
						notifications = $linq(notifications).select("x => x.notification").toArray();
					}
	                if(notifications && notifications.length > 0) {
	                	RemittanceFactory.addMultipleNotifications(notifications, vm.remittanceComponent.remittance.id).then(function(data) {
	                        if(vm.notificationBox && vm.notificationBox.search && vm.notificationBox.search.searchedValue) {
	                        	vm.notificationBox.search.searchedValue = undefined;
	                        }
	                        if(vm.notificationTableDefinition && vm.notificationTableDefinition.reloadInternalData) {
								vm.notificationTableDefinition.reloadInternalData(true, true);
							}
	                        vm.remittanceComponent.calculateCounters('remittanceNotifications', data);
                        }).catch(function (error) {
                        	console.error(error);
	                    });
	                }
	            }
				var actionsNotificationRender = function (data, type, full, meta) {
					var content = '';
					content += '<div class="btn-group dropdown flexbuttons">';
				    content += '    <a class="btn primary" tabindex="0" href="" ng-click="$ctrl.viewNotification('+full.id+')">';
					content += '        <i class="fa fa-eye" aria-hidden="true"></i>'+$filter('translate')('global.literals.see');
					content += '    </a>';
					content += '    <div class="btn-group" ng-if="$ctrl.manageRemittance" permission permission-only="[\'manage_remittance\', \'manage_all_remittance\']">';
                    content += '    	<button type="button" class="btn primary dropdown-toggle p-x-sm" data-toggle="dropdown" title="'+$filter('translate')('global.literals.showOptions')+'">';
					content += '            <span class="sr-only">'+$filter('translate')('global.literals.showOptions')+'</span>';
					content += '        </button>';
					content += '        <div class="dropdown-menu primary pull-right">';
					content += '            <button type="button" class="dropdown-item" ng-click="$ctrl.printDoc(' + full.id + ')">';
					content += '                <span>'+$filter('translate')('global.remittance.literals.printRemittanceDoc')+'</span>';
					content += '            </button>';
					content += '            <button ng-if="$ctrl.remittanceStatus == \'OPEN\'" type="button" class="dropdown-item" ng-click="$ctrl.deleteNotification(' + full.id + ')">';
					content += '                <span>'+$filter('translate')('global.literals.delete')+'</span>';
					content += '            </button>';
					content += '		</div>';
                    content += '    </div>';
					content += '</div>';
	                return content;
	            }
				
				var notificationTypeRender = function (data, type, full, meta) {
					var content = '';
					var iClass = '';
					if(full && full.notification.globalStatus){
						if(full.notification.globalStatus === 'PENDING'){
							iClass = 'text-warn';
						}else if(full.notification.globalStatus === 'SEND'){
							iClass = 'text-info';
						}else if(full.notification.globalStatus === 'COMPLETE'){
							iClass = 'text-success';
						}else if(full.notification.globalStatus === 'ERROR'){
							iClass = 'text-danger';
						}
					}
					content += '<i class="fa fa-circle m-r-xs '+iClass+'" title="{{\'global.literals.' + full.notification.globalStatus+'\' | translate}}"></i>';
	                if(data && data[vm.languageColumn]){
						content += ' '+data[vm.languageColumn];
					}
	                return content;
	            }
				
				var notificationThirdsRender = function (data, type, full, meta) {
					var content = '';
					if(full.notification.thirds && full.notification.thirds.length > 0){
						content = '<annexa-tooltip-thirds thirds="tableObjects[' + full.id + '].notification.thirds" show-first="{{true}}"></annexa-tooltip-thirds>';
					}
	                return content;
	            }
					
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }
				
				var renderNotificationLastStatus = function (data, type, full, meta) {
					if(data) {
			    		var select = []
			    		_.forEach(data, function(value){
			    			select = $linq(select).union(value.statuses).toArray();
	                    });
					}
					if (select) { 
						var orderedByDateStatuses = $linq(select).orderBy("x => x.createdDate").toArray();
					}
					
		            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
		                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
		        };

				var columnsNotification = [
					{ id: 'notification.notificationType', width: '150px', title: $filter('translate')('global.literals.type'), render:notificationTypeRender},
					{ id: 'notification.extract', width: '150px', title: $filter('translate')('global.literals.subject')},
					{ id: 'notification.thirds.third.completeName', width: '150px', title: $filter('translate')('global.literals.receivers'), render:notificationThirdsRender},
	                { id: 'notification.createdDate', width: '150px', column: new DateTimeColumn($filter, 'global.literals.creation_date')},
	                { id: 'notification.thirds', title: $filter('translate')('global.literals.state'), render: renderNotificationLastStatus, sortable: false},
	                { id: 'notification.thirds', width: '150px', column: new LastStatusUpdateNotifications($filter, 'global.literals.lastStatusUpdate'), sortable: false},
					{ id: 'actions', columnName: 'id', className: 'text-center', title: actionsTitle(), render: actionsNotificationRender, sortable: false }
		        ];
				
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.notificationTableDefinition.id = 'tableDNotificationRemittance';
				vm.notificationTableDefinition.origin = 'reg';
		        vm.notificationTableDefinition.objectType = 'RemittanceNotifications';
				vm.notificationTableDefinition.objectIdToSelectOrigin ='reg';
				vm.notificationTableDefinition.objectIdToSelectType = 'RemittanceNotifications';
		        vm.notificationTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.notificationTableDefinition.sort = tableOrderProperties.sort;
				vm.notificationTableDefinition.columns = columnsNotification;
		        vm.notificationTableDefinition.containerScope = $scope;
				vm.notificationTableDefinition.useTableObjects = true;
				vm.notificationTableDefinition.callOrigin = 'reg';
				vm.notificationTableDefinition.objectIdToSelect = angular.fromJson({remittance:{id:vm.remittanceComponent.remittance.id}});
				vm.notificationTableDefinition.objectIdToAdded = vm.remittanceComponent.remittance.id;
				
				vm.notificationBox = {
	                permissions: { new: ['manage_remittance', 'manage_all_remittance'], view: ['manage_remittance', 'manage_all_remittance']},
	                tableDefinition: vm.notificationTableDefinition,
	                isEdit: false,
	                origin: 'annexa-box-object-remittance',
					alerts: [],
					legend: undefined,
					closeBox: {value:true},
					numberBox: vm.remittanceComponent.numberNotificationsBox
            	}
				
				vm.notificationBox.hiddeNew = true;
				vm.manageRemittance = false;
				vm.remittanceStatus = vm.remittanceComponent.remittance.status;
				if((vm.remittanceStatus == 'OPEN' || vm.remittanceStatus == 'CLOSE') && AnnexaPermissionsFactory.havePermission('manage_all_remittance')) {
	            	vm.manageRemittance = true;
	            } else if((vm.remittanceStatus == 'OPEN' || vm.remittanceStatus == 'CLOSE') && AnnexaPermissionsFactory.havePermission('manage_remittance')) {
	            	vm.manageRemittance = AnnexaPermissionsFactory.haveProfile(vm.remittanceComponent.remittance.profile);
	            }
				
	            if(vm.remittanceStatus == 'OPEN' && vm.manageRemittance) {
					vm.notificationBox.search = { placeholder: 'global.literals.placeholder_search_notification', advanced: true, saveSearch: addNotificationSave, completeAdd: addNotificationSave };
	                vm.notificationBox.hiddeNew = true;
	            }
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.remittanceComponent.languageColumn;
				vm.notificationBox = {};
        		try{
        			generateNotificationBox();
					$scope.$on('annexaBoxDossierNotificationsReloadTable', function(event, args) {
			            if(vm.notificationTableDefinition && vm.notificationTableDefinition.reloadInternalData) {
							vm.notificationTableDefinition.reloadInternalData(true, true);
						}
			        });
				}catch(error){
					console.error(error);
				}
			}
        }],
        bindings: {}
    })